import * as React from "react"
import PageLayout from "../components/pageLayout";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";
import {GatsbyImage} from "gatsby-plugin-image";

const OmOssPage = () => {
    const {
        ove,
        roger,
        kristoffer,
    } = useStaticQuery(graphql`
        {
            ove: file(relativePath: { eq: "om-oss/ove.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            roger: file(relativePath: { eq: "om-oss/roger.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
            kristoffer: file(relativePath: { eq: "om-oss/kristoffer.jpg" }) {
                childImageSharp {
                    gatsbyImageData
                }
            },
        }
    `)

    const people = [
        {
            name: 'Ove Kristian Leirgulen',
            role: 'Daglig leder / CEO',
            imageUrl: ove,
            twitterUrl: '#',
            linkedinUrl: '#',
        },
        {
            name: 'Roger Bergset',
            role: 'Styreleder / CMO',
            imageUrl: roger,
            twitterUrl: '#',
            linkedinUrl: '#',
        },
        {
            name: 'Kristoffer Strøm Bergset',
            role: 'Utvikler / CTO',
            imageUrl: kristoffer,
            twitterUrl: '#',
            linkedinUrl: '#',
        },
    ]

    return (
        <PageLayout>
            <Seo title={"Om oss"}/>
            <div className="bg-secondary-light">
                <div className="relative py-16 overflow-hidden">
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                            <h1>
                                {/*<span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">*/}
                                {/*  */}
                                {/*</span>*/}
                                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
                                  Om oss
                                </span>
                            </h1>
                        </div>
                        <div className="mt-8 prose prose-indigo prose-lg text-gray-200 mx-auto">
                            <p>
                                Romvesen leverer tjenester og produkter til bedriftsmarkedet.
                                Vi selger droner med tilbehør til profesjonell bruk, og leverer tjenester innen inspeksjon, kartlegging, opplæring og bakkeradar. Primærkundene her er bygg- og anlegg, energibransjen, landbruk og offentlig sektor, men vi leverer til de som har et behov.
                            </p>
                            <p>
                                Vi har også rådgivingstjenester og bistår bedrifter som ønsker å bygge opp egen droneaktivitet, og kan her bistå i hele prosessen. Her kan vi skreddersy både systemer og opplæringsopplegg for kunden.
                            </p>
                            <p>
                                Romvesen har alle nødvendige tillatelser og forsikringer for å kunne operere effektivt i Norsk og Internasjonalt luftrom, og samarbeider aktivt med beredskaps personell og Luftfartstilsynet for alltid kunne opprettholde høyeste sikkerhetsnivå i våre operasjoner.
                            </p>
                            <p>
                                Vi har et tett samarbeid med flere av markeds ledende leverandører, og gir derfor våre kunder tilgang på de nyeste innen teknologi og programvare løsninger.
                            </p>
                            {/*<figure>*/}
                            {/*    <img*/}
                            {/*        className="w-full rounded-lg"*/}
                            {/*        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"*/}
                            {/*        alt=""*/}
                            {/*        width={1310}*/}
                            {/*        height={873}*/}
                            {/*    />*/}
                            {/*    <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption>*/}
                            {/*</figure>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-secondary">
                <div className="max-w-7xl mx-auto py-8 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
                    <div className="space-y-12">
                        {/*<div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">*/}
                        {/*    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-100">Vi er Romvesen</h2>*/}
                        {/*    <p className="text-xl text-gray-200">*/}
                        {/*        Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet lectus sit pretium egestas vel mattis*/}
                        {/*        neque.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="space-y-6">
                                        <GatsbyImage className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" image={person.imageUrl.childImageSharp.gatsbyImageData} alt={person.name} />
                                        <div className="space-y-2">
                                            <div className="text-lg leading-6 font-medium space-y-1 text-gray-100">
                                                <h3>{person.name}</h3>
                                                <p className="text-primary">{person.role}</p>
                                            </div>
                                            {/*<ul className="flex justify-center space-x-5">*/}
                                            {/*    <li>*/}
                                            {/*        <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">*/}
                                            {/*            <span className="sr-only">Twitter</span>*/}
                                            {/*            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">*/}
                                            {/*                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />*/}
                                            {/*            </svg>*/}
                                            {/*        </a>*/}
                                            {/*    </li>*/}
                                            {/*    <li>*/}
                                            {/*        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">*/}
                                            {/*            <span className="sr-only">LinkedIn</span>*/}
                                            {/*            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">*/}
                                            {/*                <path*/}
                                            {/*                    fillRule="evenodd"*/}
                                            {/*                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"*/}
                                            {/*                    clipRule="evenodd"*/}
                                            {/*                />*/}
                                            {/*            </svg>*/}
                                            {/*        </a>*/}
                                            {/*    </li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bg-secondary-light">
                <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h2 className="text-center text-3xl font-extrabold text-gray-100">Våre Kontorer</h2>
                    <div className="text-center mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
                        <div>
                            {/*<iframe className="mx-auto py-4 w-full h-64" title="Nordfjordeid" src="https://www.norgeskart.no/#!?project=norgeskart&layers=1002&zoom=10&lat=6896948.99&lon=27951.01&markerLat=6896603.953904684&markerLon=27700.684966405155&panel=searchOptionsPanel&sok=Eidsgata&type=1&marker_lat=6896603.953904684&marker_lon=27700.684966405155" width="300" height="200"></iframe>*/}
                            <h3 className="text-lg font-medium text-gray-100">Bremanger</h3>
                            <p className="mt-2 text-base text-gray-200">
                                <span className="block">Verpe 1</span>
                                <span className="block">6734 Rugsund</span>
                            </p>
                        </div>
                        <div>
                            {/*<iframe className="mx-auto py-4 w-full h-64" title="Nordfjordeid" src="https://www.norgeskart.no/#!?project=norgeskart&layers=1002&zoom=10&lat=6896948.99&lon=27951.01&markerLat=6896603.953904684&markerLon=27700.684966405155&panel=searchOptionsPanel&sok=Eidsgata&type=1&marker_lat=6896603.953904684&marker_lon=27700.684966405155" width="300" height="200"></iframe>*/}
                            <h3 className="text-lg font-medium text-gray-100">Nordfjordeid</h3>
                            <p className="mt-2 text-base text-gray-200">
                                <span className="block">Eidsgata 3B</span>
                                <span className="block">6770 Nordfjordeid</span>
                            </p>
                        </div>
                        <div>
                            {/*<iframe className="mx-auto py-4 w-full h-64" title="Nordfjordeid" src="https://www.norgeskart.no/#!?project=norgeskart&layers=1002&zoom=10&lat=6896948.99&lon=27951.01&markerLat=6896603.953904684&markerLon=27700.684966405155&panel=searchOptionsPanel&sok=Eidsgata&type=1&marker_lat=6896603.953904684&marker_lon=27700.684966405155"></iframe>*/}
                            <h3 className="text-lg font-medium text-gray-100">Førde</h3>
                            <p className="mt-2 text-base text-gray-200">
                                <span className="block">Hornneskletten 4</span>
                                <span className="block">6809 Førde</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default OmOssPage
